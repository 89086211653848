import React from "react"
import { Seo } from "src/component/common/seo"
import Casestudies from "src/component/layout/casestudies"
import Button from "src/component/common/button"
import { StaticImage } from "gatsby-plugin-image"
import AnalyticalAiCasestudyList from "src/component/list/analytical-aiCasestudyList"

export default function AnalyticalAi() {
    return (
        <>
         <Casestudies>
          <div className="analytical-ai inner-content">
            <section className="introduction__mv">
                    <div className="introduction__mv__inner c-inner-primary">
                    <div className="introduction__mv__img">
                            <StaticImage
                              src="../../assets/img/pages/casestudies/casestudies_analytical-ai.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="pc-show"
                            />
                            <StaticImage
                              src="../../assets/img/pages/casestudies/casestudies_analytical-ai-sp.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="sp-show"
                            />
                    </div>

                        <div className="introduction__mv__content">
                                <h2 className="introduction__mv__content__title">
                                 <p>分析AI</p>
                                  <span>ANALYTICAL AI</span>
                                </h2>
                                <p className="introduction__mv__lead">
                                  データに潜むパターンや関係性をAIが深層解析。
                                  <br />
                                    膨大な情報の中から重要なインサイトを抽出し、経営判断に必要な
                                    <br className="c-pc" />
                                    価値ある洞察を迅速に提示。
                                  意思決定の質とスピードを劇的に高めます。
                               </p>
                         </div>
        
                    </div>
                </section>
                



                <section className="introduction__content">
                    <div className="introduction__content__inner c-inner-primary">
                        <div className="introduction__content__title">
                            <h2 className="js-scroll">
                                <div><div><span>AIでデータ分析を<br />自動化し、的確な<br className="c-sp" />戦略立案を実現</span></div></div>
                            </h2>
                        </div>
                        <div className="introduction__content__textwrap">
                            <ul>
                                <li className="introduction__content__text">
                                 <h3>AIでROI予測・最適化</h3>
                                 <p>
                                    キャンペーンと市場データをAIが解析し、投資戦略を自然言語で即時最適化。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AIで販売・競合予測</h3>
                                 <p>
                                    市場・競合情報をAIがリアルタイム分析し、営業戦略を自然言語で具体的に提示。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AIで経費の異常検知</h3>
                                 <p>
                                    AIが経費データを監視・予測し、無駄や超過を自然言語で即座に指摘・改善。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AIが市場リスク通知</h3>
                                 <p>
                                    AIが財務・市場リスクをリアルタイムで検知し、問題の深刻度・対策を即座に通知。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AI経営分析エージェント</h3>
                                 <p>
                                    経営データをAIが自動分析。質問に即答し、重要な変化や予兆も自然言語で通知。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AIで顧客LTV最大化</h3>
                                 <p>
                                   顧客行動をマルチモーダルにAIが分析し、最適な対応を自然言語で即時に提案
                                 </p>
                                </li>

                            </ul>
         
                        </div>
                    </div>
                </section>

                <section className="p-top-casestudy">
                    <div className="p-top-casestudy__inner c-inner-primary">
                        <div className="p-top-casestudy__title c-title01">
                            <h2 className="p-top-casestudy__title__text c-title01__text">導入事例</h2>
                        </div>
                        <AnalyticalAiCasestudyList />
                    </div>
                </section>
            </div>
          </Casestudies>
        </>
    )
}


export function Head() {
    return (
        <Seo
            title="ANALYTICAL AI"
            description=""
            pathname=""
        />
    )
}